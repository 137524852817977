import * as React from 'react';
import { alpha } from '@mentimeter/ragnar-colors';
import type { Pictogram } from '../types';
import { Svg } from '../../shared-components';
import { COLOR_MAP } from '../colorMap';

export const Users = ({ userColor, variant = 'blue' }: Pictogram) => {
  return (
    <Svg viewBox="0 0 200 200" aria-hidden="true" className="light">
      <path
        d="M12.9998 141.964C12.9999 117.683 32.6834 98 56.9642 98C81.245 98 100.928 117.683 100.928 141.964L100.928 151.856H12.9997L12.9998 141.964Z"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <path
        d="M99.9998 141.964C99.9999 117.683 119.683 98 143.964 98C168.245 98 187.928 117.683 187.928 141.964L187.928 151.856H99.9997L99.9998 141.964Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
      <circle
        cx="56.4297"
        cy="64.5"
        r="22.5"
        fill={
          userColor
            ? userColor && alpha(userColor, 0.6)
            : COLOR_MAP[variant].pictogramTertiary
        }
      />
      <circle
        cx="143.43"
        cy="64.5"
        r="22.5"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramSecondary}
      />
    </Svg>
  );
};
